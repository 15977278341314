/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { Col, Row, Icon, Spin, Button, FormItem, Select, Popconfirm, Input, TextArea } from "../../common/UIComponents";
import {
  isEmpty,
  checkNegitive,
  findTotal,
  alertMessage,
  doFormate,
  base64ToPdf,
} from "../../common/Common";
import I18n from "../../common/I18n";
import AppConfig from "../../config/AppConfig";
import { fetchOrganizationDetails } from "../../api/Organisations";

import {
  invoiceHeading,
  mainHeading,
  shipperHeading,
  invoiceItem,
  totalItems,
  marginTop40,
  marginTop20,
  textBold,
  marginTop10,
  fontSize10,
  paddingTop20,
  smallContent,
  commentSection,
  borderLine,
  heading,
  alignCenter,
  alignRight,
  padding5,
  borderCollapseInherit,
  marginLeft10,
  floatLeft,
} from "../../helpers/invoice_styles";
import AddressInfo from "../common/AddressInfo";
import logo from "../../../assets/images/logo-thumbnail.png";
import userStore from "../../stores/UserStore";
import {
  fetchQuotationDetails,
  getContactEmails,
  printQuoteOrdersPdf,
  saveInvoice,
} from "../../api/Billing";
import { checkServiceExistance, formatUSPhoneNumber, renderAlertMessage } from "../../helpers/common";
import HawbMawbNo from "./HawbMawbNo";
import { findSpanValue } from "../../helpers/billing";
import BranchesIcon from "../common/BranchesIcon";
import BaseModal from "../BaseModal";
import { validateEmailList } from "../billing_screen/helpers";
import { OrgContext } from "../../context/OrgContext";
import { withRouter } from "react-router-dom";
import { sendEmailsForEstimateBilling } from "../../api/OrdersApi";
import RegenerationalConfirmationModal from "../../common/RegenerationalConfirmationModal";
import ManageInvoiceLine from "./ManageInvoiceLine";

class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: this.props.account ? this.props.account : {},
      invoice: !isEmpty(this.props.currentInvoice)
        ? this.props.currentInvoice
        : {
            account_details: {},
            warehouse_details: {},
            driver_details: {},
            order_info: {},
          },
      logo,
      chargeTypes: [
        "transportation_charges",
        "fuel_surcharges",
        "accessorial_charges",
        "adjustment_charges",
      ],
      callInvoceDetails: this.props.callInvoceDetails
        ? this.props.callInvoceDetails
        : false,
      order_id: this.props.order_id,
      showHeading:
        this.props.showHeading === false ? this.props.showHeading : true,
      showOtherDetails:
        this.props.showOtherDetails === false
          ? this.props.showOtherDetails
          : true,
      inProgress: false,
      order_references: {},
      printProgress: false,
      showEmailModal: false,
      isLoadingEmailData: false,
      listOfEmails: [],
      contactsData: [],
      subject: '',
      description: '',
      orgSettings: props.organizationSettings,
      isSendingEmailData: false,
      activeKey: '',
      currentOrderGroupType: this.props.currentOrderGroupType || "",
      dataToEdit: null
    };
  }

  componentDidMount() {
    this.getOrgLogo();
    this.setInvoiceDetails();
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.props.activeKey !== this.state.activeKey) {
      this.setState({
        activeKey: this.props.activeKey
      }, () => {
        if (this.props.activeKey == "billing") {
          this.handleGenerateQuotation(this.state.order_id);
        }
      })
    }
  }

  getZoneType = (key) => {
    const config = AppConfig.billingZones.find(
      (item) => item.key === key
    );
    return config ? config.lable : "";
  }
  
  setInvoiceDetails = () => {
    if (
      this.state.callInvoceDetails === true &&
      checkServiceExistance("OQIND")
    ) {
      this.getBillingDetails();
    } else {
      this.setUpdateRefs();
    }
  };

  getBillingDetails = () => {
    this.setState({ inProgress: true });
    const isForQuote = false;
    fetchQuotationDetails(this.state.order_id).then((result) => {
      if (result.success) {
        const invoice = result?.invoice ? result.invoice : {};
        invoice.is_for_invoice_quote = isForQuote;
        if (this.props.updateQuote) {
          this.props.updateQuote(this.state.order_id, invoice.total_amount);
        }
        this.setState(
          {
            invoice,
            inProgress: false,
          },
          () => {
            this.setUpdateRefs();
          }
        );
      } else if (result.errors) {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false });
      }
    });
  };

  getContacts = () => {
    const account = _.get(this.state, "invoice.account_details", {});
    const accId = _.get(account, "id", "");
    this.setState({ isLoadingEmailData: true });
    getContactEmails(accId).then((result) => {
      if (result.success) {
        this.setState({ contactsData : result.contact_emails || [] });
      } else if (result.errors) {
        renderAlertMessage(result.errors)
        this.setState({ isLoadingEmailData: false });
      }
    }).finally(() => {
      this.setState({ isLoadingEmailData: false });
    });
  };

  setUpdateRefs = () => {
    const { invoice, order_references } = this.state;
    if (
      invoice?.transportation_charges &&
      invoice.transportation_charges.length > 0
    ) {
      invoice.transportation_charges.map((order) => {
        order_references[order.order_number] = {};
        order_references[order.order_number].hawb = order.hawb; // doFormate(order.hawb);
        order_references[order.order_number].mawb = order.mawb;
      });
      this.setState({
        order_references,
      });
    }
  };

  handleGenerateQuotation = (id) => {
    this.setState({ inProgress: true });
    const isForQuote = false;
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    saveInvoice({ order_id: id, is_for_invoice_quote: "true" , current_date : formattedDate}).then(
      (result) => {
        if (result.success) {
          alertMessage(I18n.t("messages.quote_generated"));
          this.getBillingDetails();
          const invoice =
            !isEmpty(result.invoice) && _.isObject(result.invoice)
              ? result.invoice
              : {};
          invoice.is_for_invoice_quote = isForQuote;
          if (this.props.updateQuote) {
            this.props.updateQuote(this.state.order_id, invoice.total_amount);
          }
          this.setState({
            //  invoice,
            inProgress: false,
          });
        } else if (result.errors) {
          renderAlertMessage(result.errors)
          this.setState({ inProgress: false });
        }
      }
    );
  };

  handleUpdateAmount = (result) => {
    const isForQuote = false;
    if (result.success) {
      alertMessage(I18n.t("messages.quote_generated"));
      this.getBillingDetails();
      const invoice =
        !isEmpty(result.invoice) && _.isObject(result.invoice)
          ? result.invoice
          : {};
      invoice.is_for_invoice_quote = isForQuote;
      if (this.props.updateQuote) {
        this.props.updateQuote(this.state.order_id, invoice.total_amount);
      }
    } else if (result.errors) {
      renderAlertMessage(result.errors)
    }
  }

  handlePrintBills = async (id) => {
    // const selectedIds = !isEmpty(orderIds) ? orderIds : [];
    this.setState({ printProgress: true });
    try {
      let result = await printQuoteOrdersPdf(id);
      if (result.success) {
        base64ToPdf(
          result.quote_orders_pdf,
          `BillingEstimates-${moment().format("YYYY-MM-DDTHH:mm:ss")}.pdf`
        );
      } else {
        if (result.errors.length > 0) {
          alertMessage(result.errors.join(", "), "error", 10);
        }
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } finally {
      this.setState({ printProgress: false });
    }
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.setData === true) {
      this.setInvoiceDetails();
    }
  };

  getOrgLogo = () => {
    const orgId = userStore.getStateValue("selectedOrg");
    fetchOrganizationDetails(orgId).then((result) => {
      if (result.success) {
        const organization = result.organization || {};
        organization.logo =
          !isEmpty(organization.logo) && organization.logo.url
            ? organization.logo.url
            : "";

        if (organization.logo) {
          this.setState({
            logo: organization.logo,
          });
        } else if (!isEmpty(orgId)) {
          const urlToFile = `${AppConfig.baseUrl}/org_logos/${orgId}/org_logo.png`;
          fetch(urlToFile).then((response) => {
            if (response.status === 200) {
              this.setState({
                logo: urlToFile,
              });
            }
          });
        }
      }
    });
  };

  handleSubmit = () => {
    const {currentOrg} =  this.props
    const payload = {
      organization_id:_.get(currentOrg, "id", "") ,
      customer_order_ids: this.props.order_id,
      email_ids: this.state.listOfEmails,
      subject: this.state.subject,
      description: this.state.description,
    };
    this.setState({ isSendingEmailData: true });
    sendEmailsForEstimateBilling(payload)
      .then((result) => {
        if (result.success) {
          alertMessage(result.data.message, "success");
          this.setState({ showEmailModal: false });
        } else if (result.errors) {
          renderAlertMessage(result.errors);
        }
      })
      .finally(() => {
        this.setState({ isSendingEmailData: false });
      });
  };
  

  handleCancel = () => {
    this.setState({ showEmailModal: false,listOfEmails:[] });
  }

  handleAddingEmails = (emails) => {
    const validatedEmails = validateEmailList(emails);
    this.setState({ listOfEmails: validatedEmails });
  }

  showEmailModal = () => {
    const { orgSettings } = this.state;
    const {invoice_body_text} = orgSettings;
    this.setState({
      showEmailModal: true,
      subject: `Estimate For ${_.get(this.state, "invoice.order_number", "")}`,
      description: invoice_body_text || I18n.t('configurations.default_email_body_message')
    });
    this.getContacts();
  }

  findSectionTotal = (sectionType) => {
    const { invoice } = this.state;
    const charges = [...invoice[sectionType]];
    const priceList =
      !isEmpty(charges) && charges.length > 0
        ? charges.map((order) => order.price_list)
        : [];
    const flattenList = _.flatten(priceList);
    const amount = findTotal(flattenList, "invoice_value", 1, true);
    return amount || 0.0;
  };

  renderTermsNConditions = () => (
    <div style={{ ...marginTop40, ...borderLine }}>
      <Row>
        <Col xs={24} className="heading">
          {I18n.t("invoices.termsNconditions")}
        </Col>
      </Row>
      {AppConfig.termsList.map((term, index) => (
        <p className="paddingLeft5">
          {index + 1}. {term}
        </p>
      ))}
    </div>
  );

  renderComments = () => (
    <div style={{ ...marginTop40, ...borderLine }}>
      <div style={{ ...heading }}>{I18n.t("invoices.comments")}</div>
      <div style={{ ...commentSection }} />
    </div>
  );

  renderEmailModal = () => {
    return (
      <BaseModal
        title={I18n.t("general.send_email")}
        onCancel={this.handleCancel}
        visible={this.state.showEmailModal}
        width="50%"
        maskClosable={false}
      >
        <Spin spinning={this.state.isLoadingEmailData} delay={1000}>
          <Row className="tagsInput emailForm" type="flex" align="middle" gutter={8}>
            <Col xs={21}>
              <FormItem label={I18n.t("general.emails")}>
                <Select
                  value={this.state.listOfEmails}
                  mode="tags"
                  onChange={this.handleAddingEmails}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  tokenSeparators={[","]}
                >
                  {this.state.contactsData?.map((contact) => (
                    <Select.Option key={contact}>
                      {contact}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={3}>
             
                <Button
                 onClick={() => this.handleSubmit()}
                  type="primary"
                  loading={this.state.isSendingEmailData}
                  icon="mail"
                  style={{backgroundColor: "#607AD6",color:"#fff"}}
                  size="small"
                >
                  Send
                </Button>
       
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={"Subject"}>
                <Input
                  rows={4}
                  placeholder={"Subject"}
                  className="sop-form-textArea"
                  value={this.state.subject}
                  onChange={(e) => this.setState({ subject: e.target.value })}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem label={"Description"}>
                <TextArea
                  rows={4}
                  placeholder={"Description"}
                  className="sop-form-textArea"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </FormItem>
            </Col>
          </Row>
        </Spin>
      </BaseModal>
    );
  };
  
  renderWHDetails = () => {
    const whDetails = this.state.invoice.warehouse_details || {};
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td>
              <img src={this.state.logo} alt="" style={{ maxWidth: "10rem" }} />
            </td>
          </tr>
          <tr>
            <td width="65%">
              <h2 style={{ ...mainHeading, ...textBold }}>{whDetails.name}</h2>
            </td>
            <td width="35%">
              <h2 style={{ ...alignCenter, ...invoiceHeading, ...textBold }}>
                INVOICE
              </h2>
            </td>
          </tr>
          <tr>
            <td width="65%" style={{ ...smallContent }}>
              <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
                <tbody>
                  <tr>
                    <td style={{ ...smallContent }}>
                      <AddressInfo
                        address={(whDetails && whDetails.address) || {}}
                        showIcon
                        displayType="line"
                      />
                    </td>
                  </tr>
                  {!isEmpty(whDetails.email) && (
                    <tr>
                      <td>
                        <Icon type="mail" /> &nbsp; {whDetails.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(whDetails.contact) && (
                    <tr>
                      <td>
                        <Icon type="phone" /> &nbsp;{" "}
                        {formatUSPhoneNumber(whDetails.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="35%" style={{ ...smallContent }}>
              <table cellpadding="2px" cellspacing="0px" width="100%">
                <tbody>
                  <tr>
                    <td width="46%">{I18n.t("order.order_no")}</td>
                    <td width="4%">:</td>
                    <td width="50%">#{this.state.invoice.order_number} </td>
                  </tr>
                  <tr>
                    <td>{I18n.t("general.date")}</td>
                    <td>:</td>
                    <td>
                      {moment(this.state.invoice.date).format("MMM Do YYYY")}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderShipperDetails = () => {
    const data = this.state.invoice.account_details || {};
    const { billing_address, address } = data;
    const order_info = _.get(this.state.invoice, "order_info", {});
    return this.state.invoice.order_group_type !== "MANUAL" ? (
      <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
        <tbody>
          <tr>
            <td width="65%">
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{ ...shipperHeading, ...textBold }}>
                      <h3>{I18n.t("invoices.billed_to")}</h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...smallContent }}>
                      <h4>{this.state.invoice.account_details.name}</h4>
                      <AddressInfo
                        // address={(data && data.address) || {}}
                        address={
                          !isEmpty(billing_address)
                            ? billing_address
                            : !isEmpty(address)
                            ? address
                            : "NA" || {}
                        }
                        showIcon
                        displayType="line"
                      />
                    </td>
                  </tr>
                  {!isEmpty(data.email) && (
                    <tr>
                      <td>
                        <Icon type="mail" /> &nbsp; {data.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(data.contact) && (
                    <tr>
                      <td>
                        <Icon type="phone" /> &nbsp;{" "}
                        {formatUSPhoneNumber(data.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="35%">
              <table>
                <tbody>
                  {/* <tr>
                  <td>{I18n.t('order.weight')}</td><td>:</td><td>{this.state.invoice.order_info.order_weight ? `${this.state.invoice.order_info.order_weight} ${AppConfig.weight_unit}` : 'NA'}</td>
                </tr> */}
                  <tr>
                    <td>{I18n.t("general.Driver")}</td>
                    <td>:</td>
                    <td>
                      {this.state.invoice.driver_details
                        ? this.state.invoice.driver_details.code ||
                          this.state.invoice.driver_details.name ||
                          "NA"
                        : "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td>{I18n.t("zones.zonename")}</td>
                    <td>:</td>
                    <td>{order_info.zone_name || "NA"}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t("zones.type")}</td>
                    <td>:</td>
                    <td>{order_info.zone_type || "NA"}</td>
                  </tr>
                  {order_info.zone_type === "location" && (
                    <tr>
                      <td>{I18n.t("general.zipcode")}</td>
                      <td>:</td>
                      <td>{order_info.zipcode || "NA"} </td>
                    </tr>
                  )}
                  {order_info.zone_type === "distance" && (
                    <tr>
                      <td>{I18n.t("general.distance")}</td>
                      <td>:</td>
                      <td>
                        {!isEmpty(order_info.distance)
                          ? `${parseFloat(order_info.distance).toFixed(2)} ${
                              AppConfig.distance_unit
                            }`
                          : "NA"}{" "}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>{I18n.t("los.label")}</td>
                    <td>:</td>
                    <td>{order_info.level_of_service || "NA"}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
        <tbody>
          <tr>
            <td width="65%">
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{ ...shipperHeading, ...textBold }}>
                      <h3>{I18n.t("invoices.billed_to")}</h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...smallContent }}>
                      <h4>{this.state.invoice.account_details.name}</h4>
                      <AddressInfo
                        address={(data && data.address) || {}}
                        showIcon
                        displayType="line"
                      />
                    </td>
                  </tr>
                  {!isEmpty(data.email) && (
                    <tr>
                      <td>
                        <Icon type="mail" /> &nbsp; {data.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(data.contact) && (
                    <tr>
                      <td>
                        <Icon type="phone" /> &nbsp;{" "}
                        {formatUSPhoneNumber(data.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="35%" />
          </tr>
        </tbody>
      </table>
    );
  };

  renderReference = () => {
    const orderInfo = this.state.invoice.order_info || {};
    return (
      <Row>
        {(!isEmpty(orderInfo.mawb) || !isEmpty(orderInfo.hawb)) && (
          <Col xs={24}>
            <h4 style={{ marginBottom: 0, marginLeft: 9 }}>
              {I18n.t("order.reference_label")}
            </h4>
            {!isEmpty(orderInfo.mawb) && (
              <Row>
                <Col xs={24} style={{ marginLeft: 20, fontSize: 13 }}>
                  <span>{I18n.t("general.mawb")}&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                  <span>
                    <span>{doFormate(orderInfo.mawb)}</span>
                  </span>
                </Col>
              </Row>
            )}
            {!isEmpty(orderInfo.hawb) && (
              <Row>
                <Col xs={24} style={{ marginLeft: 20, fontSize: 13 }}>
                  <span>{I18n.t("general.hawb")}&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                  <span>
                    <span>{doFormate(orderInfo.hawb)}</span>
                  </span>
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
    );
  };

  renderDetails = () => {
    const orders_info = this.state.invoice?.orders_info
      ? this.state.invoice.orders_info
      : [];
    const account_details = this.state.invoice?.account_details
      ? this.state.invoice.account_details
      : {};
    const appointmentDate = this.props.order?.scheduled_start_datetime ? moment.utc(this.props.order.scheduled_start_datetime) : moment();
    return (
      <Fragment>
        {checkServiceExistance("BLC") && this.state.callInvoceDetails && (
          <Fragment>
            <Row>
              <Col md={24} className="alignRight">
                <Button
                  type="primary"
                  size="small"
                  icon="mail"
                  onClick={() => this.showEmailModal()}
                >
                  {I18n.t("general.email")}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  icon="printer"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginLeft: 20,
                  }}
                  onClick={() => this.handlePrintBills(this.state.order_id)}
                  loading={this.state.printProgress}
                >
                  {I18n.t("general.print")}
                </Button>
                &nbsp; &nbsp;
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    this.handleGenerateQuotation(this.state.order_id)
                  }
                >
                  {I18n.t("general.recalculate")}
                </Button>
                {/* <RegenerationalConfirmationModal
                  onConfirm={(result) => {
                    // handleGenerateQuotation(id, generated_date);
                    // setModalVisible(false);
                    this.handleUpdateAmount(result)
                  }}
                  orderId={this.state.order_id}
                  showReloadIcon={false}
                  defaultDate={appointmentDate}
            /> */}
              </Col>
              {account_details?.name && (
                <Col md={24} className="marginTop10">
                  {" "}
                  <h5>
                    Account: {account_details.name} ({account_details.code})
                  </h5>
                </Col>
              )}
            </Row>
            {!_.isEmpty(orders_info) && (
              <Row gutter={16} type="flex">
                <Col md={24} className="smallContent marginTop10">
                  {orders_info?.length ? (
                    orders_info.map((zone, index) => (
                      <Row key={index}>
                        {zone.segment_type && (
                          <Col md={3} className="textBold">
                            {zone.segment_type ? `${zone.segment_type}: ` : ""}
                          </Col>
                        )}

                        <Col md={4}>
                          <Row>
                            <Col md={24}>
                              <div className="textBold">
                                <span>
                                  {" "}
                                  <Icon type="account-book" />
                                  &nbsp;
                                  {I18n.t("zones.zonename")}&nbsp;:&nbsp;
                                </span>
                                <span>{zone.zone_name || "NA"}</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            <Col md={24}>
                              <div className="textBold">
                                <span>
                                  {" "}
                                  <Icon type="account-book" />
                                  &nbsp;
                                  {I18n.t("zones.type")}&nbsp;:&nbsp;
                                </span>
                                <span>{this.getZoneType(zone.zone_type) || "NA"}</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {["location","city-zipcode"].includes(zone.zone_type) && (
                          <>
                            <Col md={5}>
                              <Row>
                                <Col md={24}>
                                  <div className="textBold">
                                    <span>
                                      {" "}
                                      <Icon type="environment" />
                                      &nbsp;
                                      {I18n.t("general.zipcode")}&nbsp;:&nbsp;
                                    </span>
                                    <span>{zone?.zipcode || "NA"}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {/* )} */}

                            <Col md={6}>
                              <Row>
                                <Col md={24}>
                                  <div className="textBold">
                                    <span>
                                      <BranchesIcon />
                                      &nbsp;
                                      {I18n.t("general.distance")}&nbsp;:&nbsp;
                                    </span>
                                    <span>
                                      {" "}
                                      {!isEmpty(zone.distance)
                                        ? `${parseFloat(zone.distance)} ${
                                            AppConfig.distance_unit
                                          }`
                                        : "NA"}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}
                        {zone.zone_type === "distance" && (
                          <Col md={5}>
                            <Row>
                              <Col md={24}>
                                <div className="textBold">
                                  <span>
                                    <BranchesIcon />
                                    &nbsp;
                                    {I18n.t("general.distance")}&nbsp;:&nbsp;
                                  </span>
                                  <span>
                                    {" "}
                                    {!isEmpty(zone.distance)
                                      ? `${parseFloat(zone.distance)} ${
                                          AppConfig.distance_unit
                                        }`
                                      : "NA"}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    ))
                  ) : (
                    <Fragment />
                  )}
                </Col>
              </Row>
            )}
          </Fragment>
        )}
        {this.state.currentOrderGroupType !== "TEMPLATE" &&
          this.state.chargeTypes
            .filter(
              (sectionType) =>
                this.state.invoice[sectionType] &&
                this.state.invoice[sectionType].length > 0
            )
            .map((sectionType) => {
              const order_references = this.state?.order_references
                ? this.state.order_references
                : {};
              const orderObj =
                _.find(this.state.invoice.transportation_charges, {
                  order_id: this.state.order_id,
                }) || {};
              const locations =
                sectionType === "transportation_charges" && orderObj?.locations
                  ? orderObj.locations
                  : [];
              return (
                <div style={{ ...borderLine, ...marginTop20, ...padding5 }}>
                  <Row type="flex" gutter={32}>
                    <Col>
                      <h5>
                        {I18n.t(`invoices.${sectionType}`)}
                        {sectionType === "transportation_charges"
                          ? this.state.invoice.transportation_gl_code
                            ? ` ( ${I18n.t("invoices.gl_code")} : ${
                                this.state.invoice.transportation_gl_code
                              } )`
                            : ""
                          : ""}
                      </h5>
                    </Col>

                    {/* {this.state.invoice.order_info.level_of_service} */}
                    {sectionType === "transportation_charges"
                      ? locations.map((loc) => (
                          <Col>
                            <h5>
                              {loc.order_type ? (
                                <span className="textCaptilize">
                                  {_.lowerCase(loc.order_type)}
                                </span>
                              ) : (
                                ""
                              )}{" "}
                              LoS : {loc.level_of_service_code}
                            </h5>
                          </Col>
                        ))
                      : ""}
                  </Row>
                  {/* {sectionType === "adjustment_charges" && (
                    <ManageInvoiceLine
                      mode="add"
                      data={null}
                      // location_id
                      order_id={this.state.order_id}
                    
                    />
                  )} */}
                  <table
                    border="1px"
                    cellpadding="5px"
                    cellspacing="0px"
                    width="100%"
                    style={{ ...borderCollapseInherit }}
                  >
                    <tbody>
                      <tr>
                        <td width="4%" style={{ ...heading, ...alignCenter }}>
                          #
                        </td>
                        <td width="58%" className="heading">
                          {I18n.t("general.description")}
                        </td>
                        <td width="16%" className="heading">
                          {I18n.t("invoices.units")}
                        </td>
                        <td width="22%" className="heading alignRight">
                          {I18n.t("invoices.amount")}
                        </td>
                      </tr>
                      {this.state.invoice[sectionType].map((order, index) => {
                        // for not showing zero values in adjustments
                        const priceList = !isEmpty(order.price_list)
                          ? sectionType === "adjustment_charges"
                            ? order.price_list.filter(
                                (item) => item.invoice_value// > 0
                              )
                            : [...order.price_list]
                          : [];
                        return priceList.length > 0 ? (
                          <Fragment>
                            <tr>
                              <td style={{ ...invoiceItem, ...alignCenter }}>
                                {index + 1}
                              </td>
                              <td style={{ ...invoiceItem, ...textBold }}>
                                #{order.order_number}&nbsp;
                                {order_references[order.order_number] && (
                                  <HawbMawbNo
                                    record={
                                      order_references[order.order_number]
                                    }
                                  />
                                )}
                              </td>
                              <td style={{ ...invoiceItem }}>&nbsp;</td>
                              <td style={{ ...invoiceItem, ...alignRight }}>
                                &nbsp;
                              </td>
                            </tr>

                            {priceList // filter(invoiceItem => invoiceItem.invoice_value !== 0)
                              .map((item, itemIndex) => {
                                const rowSpan = findSpanValue(
                                  priceList,
                                  item,
                                  itemIndex
                                );
                                return (
                                  <tr>
                                    <td
                                      style={{ ...invoiceItem, ...alignCenter }}
                                    >
                                      {/* {sectionType === "adjustment_charges"
                                ? itemIndex + 1
                                : ""} */}
                                    </td>
                                    <td style={{ ...invoiceItem }}>
                                      {item.invoice_name}{" "}
                                      {item.item_description ? (
                                        <i style={{ ...fontSize10 }}>
                                          {" "}
                                          {item.item_description}{" "}
                                        </i>
                                      ) : (
                                        ""
                                      )}
                                      {item.added_from === "MANUAL" && (
                                        <sup className="textRed textBold">
                                          M
                                        </sup>
                                      )}
                                    </td>
                                    {rowSpan !== 0 && (
                                      <td
                                        style={{ ...invoiceItem }}
                                        rowSpan={rowSpan}
                                      >
                                        {rowSpan === 1 ? (
                                          !isEmpty(item.invoice_quantity) &&
                                          item.invoice_quantity !== 0 ? (
                                            item.invoice_quantity
                                          ) : (
                                            <Fragment>&nbsp;</Fragment>
                                          )
                                        ) : (
                                          rowSpan
                                        )}
                                      </td>
                                    )}
                                    {rowSpan !== 0 && (
                                      <td
                                        style={{
                                          ...invoiceItem,
                                          ...alignRight,
                                        }}
                                        rowSpan={rowSpan}
                                      >
                                        {checkNegitive(item.invoice_value)}
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                          </Fragment>
                        ) : (
                          <Fragment />
                        );
                      })}
                    </tbody>
                  </table>

                  <table width="100%" style={{ ...marginTop20 }}>
                    <tbody>
                      <tr>
                        <td width="62.5%" style={{ ...marginTop40 }} />
                        <td width="37.5%">
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td
                                  width="42%"
                                  style={{ ...alignRight, ...totalItems }}
                                >
                                  {I18n.t("invoices.sub_total")}
                                </td>
                                <td
                                  width="58%"
                                  style={{
                                    ...alignRight,
                                    ...totalItems,
                                    ...textBold,
                                  }}
                                >
                                  {this.findSectionTotal(sectionType)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        {this.state.currentOrderGroupType === "TEMPLATE" && (
          <div style={{ ...borderLine, ...marginTop20, ...padding5 }}>
            <Row type="flex" gutter={32}>
              <Col>
                <h5>Charges</h5>
              </Col>
            </Row>
            <table
              border="1px"
              cellPadding="5px"
              cellSpacing="0px"
              width="100%"
              style={{ ...borderCollapseInherit }}
            >
              <tbody>
                <tr>
                  <td width="4%" style={{ ...heading, ...alignCenter }}>
                    #
                  </td>
                  <td width="58%" className="heading">
                    {I18n.t("general.description")}
                  </td>
                  <td width="16%" className="heading">
                    {I18n.t("invoices.units")}
                  </td>
                  <td width="22%" className="heading alignRight">
                    {I18n.t("invoices.amount")}
                  </td>
                </tr>
                {this.state.invoice.transportation_charges.map(
                  (order, index) => {
                    const priceList = !isEmpty(order.price_list)
                      ? order.price_list
                      : [];
                    return priceList.length > 0 ? (
                      <Fragment key={order.order_number}>
                        <tr>
                          <td style={{ ...invoiceItem, ...alignCenter }}>
                            {index + 1}
                          </td>
                          <td style={{ ...invoiceItem, ...textBold }}>
                            #{order.order_number}&nbsp;
                            {this.state?.order_references?.[
                              order.order_number
                            ] && (
                              <HawbMawbNo
                                record={
                                  this.state.order_references[
                                    order.order_number
                                  ]
                                }
                              />
                            )}
                          </td>
                          <td style={{ ...invoiceItem }}>&nbsp;</td>
                          <td style={{ ...invoiceItem, ...alignRight }}>
                            &nbsp;
                          </td>
                        </tr>
                        {priceList.map((item, itemIndex) => {
                          const rowSpan = findSpanValue(
                            priceList,
                            item,
                            itemIndex
                          );
                          return (
                            <tr key={itemIndex}>
                              <td style={{ ...invoiceItem, ...alignCenter }} />
                              <td style={{ ...invoiceItem }}>
                                {item.invoice_name}{" "}
                                {item.item_description && (
                                  <i style={{ ...fontSize10 }}>
                                    {" "}
                                    {item.item_description}{" "}
                                  </i>
                                )}
                                {item.added_from === "MANUAL" && (
                                  <sup className="textRed textBold">M</sup>
                                )}
                              </td>
                              {rowSpan !== 0 && (
                                <td
                                  style={{ ...invoiceItem }}
                                  rowSpan={rowSpan}
                                >
                                  {rowSpan === 1 ? (
                                    !isEmpty(item.invoice_quantity) &&
                                    item.invoice_quantity !== 0 ? (
                                      item.invoice_quantity
                                    ) : (
                                      <Fragment>&nbsp;</Fragment>
                                    )
                                  ) : (
                                    rowSpan
                                  )}
                                </td>
                              )}
                              {rowSpan !== 0 && (
                                <td
                                  style={{ ...invoiceItem, ...alignRight }}
                                  rowSpan={rowSpan}
                                >
                                  {checkNegitive(item.invoice_value)}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <Fragment key={order.order_number} />
                    );
                  }
                )}
              </tbody>
            </table>
            <table width="100%" style={{ ...marginTop20 }}>
              <tbody>
                <tr>
                  <td width="62.5%" style={{ ...marginTop40 }} />
                  <td width="37.5%">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td
                            width="42%"
                            style={{ ...alignRight, ...totalItems }}
                          >
                            {I18n.t("invoices.sub_total")}
                          </td>
                          <td
                            width="58%"
                            style={{
                              ...alignRight,
                              ...totalItems,
                              ...textBold,
                            }}
                          >
                            {this.findSectionTotal("transportation_charges")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <table width="100%">
          <tbody>
            <tr>
              <td width="62.5%" style={{ ...marginTop40 }}>
                {this.state.showOtherDetails && this.renderComments()}
              </td>
              <td width="37.5%" style={{ ...paddingTop20 }}>
                <table width="100%">
                  <tbody>
                    {false && (
                      <tr>
                        <td
                          width="42%"
                          style={{ ...alignRight, ...totalItems }}
                        >
                          {I18n.t("invoices.sub_total")}
                        </td>
                        <td
                          width="58%"
                          style={{ ...alignRight, ...totalItems, ...textBold }}
                        >
                          {this.state.currentOrderGroupType !== "TEMPLATE"
                            ? this.state.invoice.sub_total
                              ? checkNegitive(this.state.invoice.sub_total)
                              : checkNegitive(this.state.invoice.total_amount)
                            : ""}
                          {this.state.currentOrderGroupType === "TEMPLATE" &&
                            this.findSectionTotal("transportation_charges")}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td width="42%" style={{ ...alignRight, ...totalItems }}>
                        {I18n.t("invoices.taxable")}
                      </td>
                      <td
                        width="58%"
                        style={{ ...alignRight, ...totalItems, ...textBold }}
                      >
                        {checkNegitive(this.state.invoice.taxable)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="42%"
                        style={{ ...alignRight, ...totalItems, ...textBold }}
                      >
                        {I18n.t("invoices.total")}
                      </td>
                      <td
                        width="58%"
                        style={{ ...alignRight, ...totalItems, ...textBold }}
                      >
                        {checkNegitive(this.state.invoice.total_amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style={{ height: 85 }} />
                    </tr>
                    {this.state.showOtherDetails && (
                      <tr>
                        <td
                          colspan="2"
                          style={{
                            ...alignCenter,
                            ...textBold,
                            ...totalItems,
                          }}
                        >
                          [{" "}
                          {this.state.invoice.organization_name ||
                            "Company Name"}{" "}
                          ]
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.showEmailModal && this.renderEmailModal()}
      </Fragment>
    );
  };
  renderSignatutes = () => {
    const podPics = this.state.invoice.pod_pictures
      ? this.state.invoice.pod_pictures
      : [];
    const signaturePics = [];
    podPics.forEach((picData) => {
      if (picData.pictures) {
        const signs = picData.pictures
          .filter(
            (pic) =>
              pic.image_type === "signature" && pic.picture && pic.picture.url
          )
          .map((pic) => {
            return { url: pic.picture.url, sign_by: pic.sign_by };
          });
        if (signs.length > 0) {
          signaturePics.push({
            sign: signs[0].url,
            order_number: picData.customer_order_number,
            sign_by: signs[0].sign_by,
          });
        }
      }
    });
    return (
      <Fragment>
        {signaturePics.map((pic, index) =>
          index === 0 ? (
            <div
              class="textCenter"
              style={{
                width: "100%",
                ...floatLeft,
                ...borderLine,
                ...padding5,
                ...marginTop10,
                ...marginLeft10,
              }}
            >
              <div>
                <img src={pic.sign} alt="sign" style={{ width: "100%" }} />
              </div>
              <div style={{ ...alignCenter, ...textBold }}>
                Sign By: {pic.sign_by}
              </div>
            </div>
          ) : null
        )}
      </Fragment>
    );
  };

  renderPodPictures = () => {
    const podPics = this.state.invoice.pod_pictures
      ? this.state.invoice.pod_pictures
      : [];
    const normalPics = [];
    podPics.forEach((picData) => {
      if (picData.pictures) {
        const signs = picData.pictures
          .filter(
            (pic) =>
              pic.image_type === "normal" && pic.picture && pic.picture.url
          )
          .map((pic) => {
            return { url: pic.picture.url, pic_title: pic.pic_title };
          });
        if (signs.length > 0) {
          normalPics.push({
            sign: signs[0].url,
            order_number: picData.customer_order_number,
          });
        }
      }
    });
    return (
      <Fragment>
        {normalPics.map((pic, index) => (
          <div
            class="textCenter"
            style={{
              width: "100%",
              ...floatLeft,
              ...borderLine,
              ...padding5,
              ...marginTop10,
              ...marginLeft10,
            }}
            key={`image${index}`}
          >
            <div>
              <img src={pic.sign} alt="PIC" style={{ width: "100%" }} />
            </div>
            <div style={{ ...alignCenter }}>Order No: #{pic.order_number}</div>
          </div>
        ))}
      </Fragment>
    );
  };

  _renderInvoce = () => {
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
        {this.state.showHeading && this.renderWHDetails()}
        {this.state.showOtherDetails && this.renderShipperDetails()}
        {false && this.renderReference()}
        {this.renderDetails()}
        {this.state.showOtherDetails && (
          <Fragment>
            {this.renderPodPictures()}
            {this.renderSignatutes()}
            <Row>
              <Col
                xs={24}
                className="alignCenter smallText marginTop40 fontSize15"
              >
                For all terms & conditions please refer{" "}
                <a href="https://fleetenable.com/">
                  <u className="text-decoration-dashed">here</u>
                </a>
              </Col>
            </Row>
          </Fragment>
        )}
      </Spin>
    );
  };

  render() {
    const { account } = this.state;
    return (
      <div className="invoice-box">
        <this._renderInvoce />
      </div>
    );
  }
}

export const Form = withRouter((props) => {
  const { orgSettings : organizationSettings, currentOrg  }= useContext(OrgContext);
  return <FormComponent {...props} organizationSettings={organizationSettings} currentOrg={currentOrg} />;
});

export default Form;